.login-container

h2, p {
    color: white;
}

p {
    font-family: Arial, Helvetica, sans-serif;
    width:100%;
    text-align: center;
}



input[type=button] {
    border:none;
    color:black;
    background-color: rgb(248, 246, 141);
    width:200px;height:40px;
    display:block;
    transition: .4s;
    cursor: pointer;
    -webkit-appearance: none;
}
input[type=button]:hover {
    background-color:white;
}

form {
    width:500px;
    background-color:gray;
}

@media only screen and (max-width: 600px) {
    form {
        width:100%;
        background-color: red;
    }
}

