.return-container {
    position: fixed;
    left:0;top:0;
    height:60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    padding: 0 20px;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    z-index: 100;
    transition: .5s;
}
.return-container:hover {
    color:rgb(248, 246, 141);
}