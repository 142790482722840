

@font-face {
    font-family: 'LEDLIGHT';
    src: local('LEDLIGHT'), url('../assets/LEDLIGHT.otf') format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }


html, body {
    font-family: 'LEDLIGHT';
}


.container-center {
    position: fixed;
    top:0;right:0;left:0;bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom:20%;
    padding-left: 0;padding-right:0;
    margin:0px;
}


.logo {
    display: block;
    height:300px;
    width:900px;
    object-fit: cover;

}

@media only screen and (max-width: 600px) {
    .logo {
        width:100%;
        margin:0px;
        padding:0px;
    }
}

@media only screen and (max-width: 800px) {
    .links .link {
        display:flex;
        align-content: center;
        justify-content: center;
    }


}


.links {
    width: 100%;
    display: block;
    height:auto;
    display:flex;
    justify-content: center;

}

.link {
    background-color: transparent;
    color:white;
    margin:6px;
    padding:5px 25px;
    font-size: 20px;
    cursor: pointer;
    height:30px;
    line-height:30px;
    display: inline-block;
}

.links .link:hover {
    background-color: white;
    color: black;
}

.background-wrapper {
    position: absolute;
    top:0;bottom:0;right:0;left:0;
    z-index: -1;
    background-color: black;
   
}

